import DEFAULT_LIST from '../constants/default.tokenlist.json'
import placeholder from "../assets/placeholder.png"
const DEFAULT_TOKEN_MAP = {}

for(let token of DEFAULT_LIST.tokens) {
    DEFAULT_TOKEN_MAP[token.address.toLowerCase()] = token;
}

export function getLogoUrl(tokenAddress) {
    if ("0x517e9e5d46c1ea8ab6f78677d6114ef47f71f6c4".toLocaleLowerCase() === tokenAddress.toLowerCase()) {
        return "https://tokswap.net/static/media/ela-logo.f457b657.png"
    }
    const token = DEFAULT_TOKEN_MAP[tokenAddress.toLowerCase()]
    if (token) {
        return token.logoURI
    }
    else {
        return placeholder
    }
}