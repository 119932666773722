import axios from "axios";
if (window.ethereum) {
    window.ethereum.on('chainChanged', (chainId) => {
        window.location.reload();
    });
}
async function getAllPairs() {
    let chain = null;
    if (window.ethereum && window.ethereum.chainId) {
        chain = parseInt(window.ethereum.chainId);
    }
    const response = await axios.get('/api/v1/pair/all', {
        params: {
            chain,
            _t: new Date().getTime()
        }
    })
    if (response.status === 200) {
        if (response.data.code === 1) {
            return response.data.data;
        }
    }
    return [];
}
async function getPairsBluk(pairList) {
    let chain = null;
    if (window.ethereum && window.ethereum.chainId) {
        chain = parseInt(window.ethereum.chainId);
    }
    const response = await axios.post('/api/v1/pair/bluk', {
        pairList
    }, {
        params: {
          chain,
          _t: new Date().getTime()
        }
    })
    if (response.status === 200) {
        if (response.data.code === 1) {
            return response.data;
        }
    }
    return {
        data: {
            pairs: []
        }
    };
}

async function getPairTransactions(pairAddress) {
    let chain = null;
    if (window.ethereum && window.ethereum.chainId) {
        chain = parseInt(window.ethereum.chainId);
    }
    const response = await axios.get('/api/v1/pair/transactions', {
        params: {
            chain,
            address: pairAddress,
            _t: new Date().getTime()
        }
    })
    if (response.status === 200) {
        if (response.data.code === 1) {
            return response.data;
        }
    }
    return [];
}

async function getCurrentPairs() {
    let chain = null;
    if (window.ethereum && window.ethereum.chainId) {
        chain = parseInt(window.ethereum.chainId);
    }
    const response = await axios.get('/api/v1/pair/current', {
        params: {
            chain,
            _t: new Date().getTime()
        }
    })
    if (response.status === 200) {
        if (response.data.code === 1) {
            return response.data;
        }
    }
    return {
        data: {
            pairs: []
        }
    };
}

async function getPairChart(pairAddress) {
    let chain = null;
    if (window.ethereum && window.ethereum.chainId) {
        chain = parseInt(window.ethereum.chainId);
    }
    const response = await axios.get('/api/v1/pair/chart', {
        params: {
            address: pairAddress,
            chain,
            _t: new Date().getTime()
        }
    })
    if (response.status === 200) {
        if (response.data.code === 1) {
            return response.data;
        }
    }
    return {
        data: {
            pairs: []
        }
    };
}

export default {
    getAllPairs,
    getPairsBluk,
    getPairTransactions,
    getCurrentPairs,
    getPairChart,
}
